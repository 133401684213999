import React, { FC } from "react";
import styled from "styled-components";
import TerminalLink from "./TerminalLink";

const Post: FC<{ post: PostInterface }> = ({ post }) => {
  const { data } = post;
  return (
    <Box>
      <TitleWrapper>
        <TerminalLink to={"/" + post.uid} text={data.title[0].text} size={24} />
      </TitleWrapper>
      {data.posttext &&
        data.posttext.map(({ text }: { text: string }, index: number) => {
          return <p key={index}>{text}</p>;
        })}
      {data.images &&
        data.images.map(({ image }) => {
          return (
            <Image
              width={image.dimensions.width}
              height={image.dimensions.height}
              key={image.alt}
              src={image.url.split("?")[0]}
              alt={image.alt}
            />
          );
        })}
    </Box>
  );
};

const Box = styled.div`
  border-style: double;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 0 6px #c8c8c8, inset 0 0 6px #c8c8c8;
`;
const Image = styled.img`
  :first-of-type {
    margin-left: -19px;
  }
  border-style: double;
  padding: 16px;
  margin-bottom: -24px;
  box-shadow: 0 0 6px #c8c8c8, inset 0 0 6px #c8c8c8;
  border-bottom: none;
  border-left: none;
`;

const TitleWrapper = styled.div`
  display: inline-block;
`;

export default Post;
