import React from "react";
import { graphql } from "gatsby";
import Post from "../components/Post";

const Index = ({
  data: { allPrismicPost }
}: {
  data: { allPrismicPost: { edges: [{ node: PostInterface }] } };
}) => {
  return (
    <div style={{ marginRight: "2rem", marginBottom: 64 }}>
      {allPrismicPost.edges.map((edge) => {
        return <Post key={edge.node.id} post={edge.node} />;
      })}
    </div>
  );
};

export default Index;
export const IndexQuery = graphql`
  {
    allPrismicPost(
      limit: 10
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          data {
            title {
              text
            }
            images {
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
            }
            posttext {
              text
            }
          }
        }
      }
    }
  }
`;
